/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: "Quicksand-Light";
  src: url("assets/fonts/Quicksand-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Quicksand-Regular";
  src: url("assets/fonts/Quicksand-Regular.ttf") format("truetype");
}

html,
body {
  overflow: none;
  margin: 0;
  padding: 0;
}

html {
  height: -webkit-fill-available;
}
.main-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 43px;
  border: none;
  font-family: "Quicksand-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.main-button__primary {
  background: #ef4b7d;
  color: #fff;
}
.main-button__primary-disabled {
  background-color: #d4d4d4 !important;
  color: #555555;
  border: 1px solid #d4d4d4 !important;
  &:hover {
    cursor: not-allowed !important;
  }
}
.main-button__secondary {
  background: #6e699e;
  color: #fff;
}

.page {
  margin-top: 40px;
  height: auto;
  min-height: 88vh;
  display: flex;
  flex-direction: column;
}
.mat-menu-users {
  border-radius: 4px !important;
  border: 1px solid #9390b5 !important;
  background: #fff !important;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.25) !important;
  display: inline-flex !important;
  padding: 10px !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  gap: 10px !important;

  button {
    display: flex !important;
    flex-direction: column !important;
    text-decoration: none !important;
    background-color: transparent !important;
    color: #000;
    font-family: "Quicksand-Light";
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    border: none !important;
    cursor: pointer;
  }

  .mat-menu-content:not(:empty) {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}
.button-add-user {
  width: 33.737px;
  height: 32.36px;
  border-radius: 50%;
  background-color: #140f5c;
  margin-left: 20px;
  cursor: pointer;

  i {
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    height: 100%;
  }
}
.logo-container {
  width: 90px;
  margin-right: 20px;
  min-width: 90px;
}

.logo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 20px;
}

.place-info-container {
  width: 75%;
}

.personal-info {
  display: flex;
  text-align: start;
  justify-content: center;
  flex-direction: column;
}

b {
  font: 400 16px / normal "Quicksand-Light";
  color: #fff;
  font-style: normal;
  margin: 0px;
  height: auto;
}

.container-spacer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.spacer-user {
  border-color: #f5861d;
  width: 100%;
}

button {
  i {
    color: #fff;
    transition: all 0.5s ease-in;
  }

  &:hover {
    i {
      color: #f5861d;
    }
  }
}

.search-user-container {
  width: 390px;
  position: relative;

  input {
    display: flex;
    width: 360px;
    padding: 10px 16px;
    align-items: center;
    gap: 257px;
    border-radius: 23px;
    background: #d9d9d9;
    color: #140f5c;
    font-family: "Quicksand-Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
  }

  mat-icon {
    color: #140f5c;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
}

.search-buttons {
  width: auto;
  display: flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 21px;
  background: #6e699e;
  margin: 15px;
  cursor: pointer;

  b {
    color: #fff;
    text-align: center;
    font-family: "Quicksand-Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.row {
  flex-direction: row;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  //flex-wrap: wrap;
}
.row-responsive {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
}

.col {
  flex-direction: column;
  display: flex;
}

.justify-content-center {
  justify-content: center;
}
.d-block {
  display: block !important;
}

.form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.mh-auto {
  margin-left: auto;
  margin-right: auto;
}
.grid-container {
  display: grid;
  grid-template-columns: auto;
  padding: 10px;
}
.container-table {
  padding: 1rem;
  display: flex;
}

.active {
  background: var(--Acento, #ef4b7d);
}

@media (max-width: 412px) {
  .logo-container {
    width: 20%;
    min-width: 90px;
  }
  .place-info-container {
    width: 80%;
  }
  .logo {
    width: 70%;
    height: auto;
    margin-right: 20px;
  }
  .text {
    font-size: 4vw;
  }
  .width-150 {
    width: 150%;
  }
}

.h-35 {
  height: 35px !important;
}

.align-self-center {
  align-self: center;
}

// ============ CONTAINERS & DIRECTIONS ============
.container {
  display: flex;
  padding: 0px 15px 0px 15px;
  align-content: space-between;

  @media (max-width: 500px) {
    padding: 0px 10px 0px 10px;
  }
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.center {
  text-align: center;
  margin: 0 auto auto;
  justify-content: center;
  display: flex;
}

.center-y {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.center-x {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

//cards
.card {
  display: flex;
  flex-wrap: wrap;
  background: rgba(128, 124, 124, 0.2);
  width: 20%;
  align-items: center;
  float: left;
  margin-right: 10px;
}

.card-product {
  padding: 10px;
  background-color: #140f5c2e;
  border-radius: 6px;
  h3 {
    color: #000000 !important;
    font-family: "Quicksand-Regular";
    font-size: 18px !important;
    font-weight: 600 !important;
    text-align: left !important;
  }
}

.container-save {
  display: flex;
  justify-content: flex-end;
  margin-block: 5px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-fonts {
  width: 100%;
  justify-content: center;
  h2 {
    color: #140f5c;
    font-family: "Quicksand-regurlar";
    font-size: 20px !important;
    font-weight: 700;
    text-align: center !important;
  }
}

//modal

.custom-dialog .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 16px !important;
  border: 1px solid #6e699e !important;
}

.header-modal {
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  mat-icon {
    &:hover {
      cursor: pointer;
    }
  }
}

.modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: -15px;
  position: relative;
}

.modal {
  position: relative;
  flex-direction: column;
}

.modal-text-size {
  font-size: 20px;
  text-align: initial;
}

//textarea

.textarea-style {
  font-family: "Quicksand-Regular" !important;
  font-size: 16px;
  font-weight: 400;
  border: none;
  border-radius: 10px;
  outline: none;
  resize: none;
  height: 70px;
}

.inputs-container {
  display: flex;
  flex-direction: column;
  padding-block: 10px;
}

//fonts

h1 {
  font: 700 25px / normal "Quicksand-Regular";
  font-style: normal;
  color: #140f5c;
}

h2 {
  font: 700 20px / normal "Quicksand-Regular";
  color: #140f5c;
  font-style: normal;
}

h3 {
  font: 700 18px / normal "Quicksand-Regular";
  color: #140f5c;
  font-style: normal;
}

h4 {
  font: 700 15px / normal "Quicksand-Regular";
  color: #140f5c;
  font-style: normal;
}

h5 {
  font-family: "Quicksand-Regular";
  font-size: 14px;
}

p {
  font: 200 16px / normal "Quicksand-Regular";
  color: #000;
  text-align: justify;
  margin: 0;
}

.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control,
.mat-mdc-button,
.mat-mdc-form-field,
.mdc-form-field > label,
.mat-sort-header-content,
.mat-mdc-paginator-page-size-label,
.mat-mdc-paginator-range-label,
.mat-mdc-table .mat-mdc-header-cell {
  font: 500 16px/20px "Quicksand-Regular" !important;
}

//arrow
.goback-arrow {
  height: 100%;
  width: auto;
}

a {
  font-weight: 600;

  transition: all 0.5s ease-in-out;
}

//width
each(range(1%, 100%, 1), {
    .w-@{index} {
      width: @value;
    }
  });

//height
each(range(1%, 100%, 1), {
    .h-@{index} {
      height: @value;
    }
  });

each(range(1%, 100%, 1), {
    .h-@{index} {
      height: @value;
    }
  });

/* ============ PADDING ============ */

.p-0 {
  padding: 0 !important;
}
.p-v-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.p-h-5 {
  padding-left: 5px;
  padding-right: 5px;
}
each(range(1rem, 100rem, 1rem), {
    .ph-@{index} {
      padding-left: @value;
      padding-right: @value;
    }
    .pv-@{index}{
      padding-top: @value;
      padding-bottom: @value;
    }
  });

/* ============ MARGIN ============ */
each(range(1rem, 100rem, 1rem), {
  .mt-@{index} {
    margin-top: @value;
  }
  .mb-@{index}{
    margin-bottom: @value;
  }
  .mr-@{index}{
    margin-right: @value;
  }
  .ml-@{index}{
    margin-left: @value;
  }
});

.m-x {
  margin-inline: 2rem;
}

//snackbar

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: transparent !important;
  padding: 0;
}
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  box-shadow: none;
}
.mdc-snackbar__label {
  padding: 0 !important;
}

.snackbar-error {
  border: 3px solid rgb(207, 3, 3);
  background-color: rgb(207, 3, 3) !important;
  color: white !important;
  border-radius: 10px !important;
  font-weight: bold;
  .mat-simple-snackbar-action {
    color: white;
  }
}

.snackbar {
  border: 3px solid rgb(81, 207, 3);
  background-color: rgb(81, 207, 3) !important;
  color: black !important;
  border-radius: 10px !important;
  font-weight: bold;
  .mat-simple-snackbar-action {
    color: black;
  }
}
/*=====Swal======*/
.btn-confirm {
  padding: 10px 30px !important;
  border-radius: 20px !important;
  background-color: #f53173 !important;
  outline: none !important;
  cursor: pointer !important;
  border: 1px solid #f53173 !important;
  outline: none !important;
  box-shadow: none !important;
}

.btn-cancel {
  padding: 10px 20px !important;
  border-radius: 20px !important;
  background-color: #6e699e !important;
  border: 1px solid #6e699e !important;
  cursor: pointer !important;
  outline: none !important;
}
.swal2-border-radius {
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  border-radius: 20px !important;
}
/*=====Swal======*/
.bg-blue {
  color: #140f5c;
}

.border-active {
  border: 1px solid #140f5c !important;
  padding: 8px 10px !important;
}

.bg-icon-check {
  color: #f5861d;
}
/*===btn===========*/
.btn-add {
  margin-left: 5px;
  background-color: #140f5c !important;
  width: 40px;
  height: 40px;
  outline: none;
  border: 1px solid #140f5c;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
  &:hover {
    background-color: #fff;
    color: #140f5c;
    cursor: pointer;
  }
}

.description-margin {
  margin-left: -450px;
}

///component upload file
.container-file {
  width: 90%;
  text-align: center;
  margin-block: 10px;
  padding: 10px 16px 10px 16px;
  border-radius: 10px;
  border: 1px dashed #f5861d;
  color: #ccc;
  font-size: 30px;
  .item-file {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
    }
  }
  &:hover {
    cursor: pointer;
  }
}

.container-file-upload {
  border: 1px dashed #00a162 !important;
}
.container-file-disabled {
  border: 1px dashed #d4d4d4 !important;
  &:hover {
    cursor: not-allowed !important;
  }
}

.view-voucher {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  button {
    outline: none;
    border: none;
    background-color: transparent;
    color: #140f5c;
    font-family: "Quicksand-Regular";
    font-size: 20px;
    font-weight: 700;
    cursor: "pointer";
  }
  &:hover {
    cursor: pointer;
  }
}
/*==Quote==*/
.card-quote {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 331px;
  padding: 21px;
  border-radius: 32px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
  flex-wrap: wrap;
  background-color: #e7e7ef;
  transition: transform 0.3s ease;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
  .info-user {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding-bottom: 5px;
    border-bottom: solid 2px #f48a18;

    .img-container {
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
      .container-contact {
        width: 100%;
        display: flex;
        justify-content: space-around;
      }
    }
    .info-user-details {
      margin-left: 5px;
      h3 {
        color: #000;
        font-family: "Quicksand-Regular";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      ul {
        list-style: none;
        padding: 0;
        width: 100%;
        li {
          color: #000;
          font-family: "Quicksand-Regular";
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-block: 5px;
        }
      }
    }
  }
  .info-products {
    width: 100%;
    padding-inline: 5px;
    ul {
      width: 100%;
      list-style: none;
      padding: 0;
      li {
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid #fff;
      }
    }
    .edit-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      .btn-edit {
        padding: 6px 23px;
        color: #fff;
        border: 1px solid #f53173;
        outline: none;
        border-radius: 27px;
        background-color: #f53173;
        margin-block: 5px;
        &:hover {
          cursor: pointer;
          background-color: #fff;
          color: #f53173;
        }
      }
      .btn-reject {
        padding: 6px 23px;
        color: #fff;
        border: 1px solid #6e699e;
        outline: none;
        margin-block: 5px;
        border-radius: 27px;
        background-color: #6e699e;
        &:hover {
          cursor: pointer;
          background-color: #fff;
          color: #6e699e;
        }
      }
    }
    .progress-bar__container {
      width: 100%;
      height: 1rem;
      border-radius: 2rem;
      position: relative;
      overflow: hidden;
      transition: all 0.5s;
      will-change: transform;
      background-color: #13095466;
      .progress-bar {
        position: absolute;
        height: 100%;
        background-color: #cf406b;
        border-radius: inherit;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.btn-reject {
  margin-right: 5px;
  padding: 6px 23px;
  color: #fff;
  border: 1px solid #6e699e;
  outline: none;
  margin-block: 5px;
  border-radius: 27px;
  background-color: #6e699e;
  &:hover {
    cursor: pointer;
    background-color: #fff;
    color: #6e699e;
  }
}
.pointer {
  cursor: pointer;
}

.is-invalid {
  border: 2px solid red !important;
}

.show-error-image {
  flex-direction: column;
  align-items: center;
}

.icon-red {
  color: red !important;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

//error
.error-message {
  margin: -5px 0px 10px 10px !important;
  font-size: 14px !important;
  color: red;
}

.input-disabled {
  &:hover {
    cursor: not-allowed !important;
  }
}

//icons
.my-icon {
  color: #f5861d;
}

//colors
.color-white {
  color: #fff !important;
}

.color-accent {
  color: #140f5c !important;
}

.mat-drawer-inner-container {
  overflow: hidden !important;
}

// styles of scrollbar
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background: #79797999;
  border-radius: 25px;
}
/*--status---*/
.container-status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .actions-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .action {
        padding: 5px;
        margin-inline: 5px;
        border-top: 2px solid #000;
        display: grid;
        grid-template-columns: 2fr 7fr 1fr;
        gap: 5px;
        .btns-action {
          display: flex;
          gap: 5px;
          .btn-action {
            display: flex;
            width: 40px;
            height: 40px;
            padding: 8px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-shrink: 0;
            background-color: #fff;
            border-radius: 26px;
            border: 1px solid #b0acd8;
            &:hover {
              cursor: pointer;
            }
          }
        }
        .description-container {
          border-radius: 20px;
          border: 1px solid #b0acd8;
          padding: 8px 10px;
          background: #fff;
        }
        .name-container {
          border-radius: 20px;
          border: 1px solid #b0acd8;
          padding: 0px 10px;
          background: #fff;
        }
      }
    }
  }
}
/*stores*/
.card-store {
  display: flex;
  padding: 20px;
  margin: 0 auto;
  width: 100%;
  max-width: 300px;
  margin-block: 10px;
  border-radius: 8px 30px 30px 30px;
  background-color: #e7e7ef;

  .card-info {
    margin-left: 10px;
    width: 100%;
    max-width: 150px;
    .site {
      width: 100%;
      border-bottom: 1px solid #000000;
    }
    .actions {
      display: flex;
      justify-content: flex-end;
      mat-icon {
        color: #140f5c;
      }
    }
  }
  .img-card {
    max-width: 150px;
    width: 100%;
    .img-container {
      max-width: 150px;
      height: 200px;
      border-radius: 5px;
      padding: 5px;
      margin-block: 5px;
      .icon-container {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .link-container {
    width: 100%;
    max-width: 200px;
    .link {
      text-decoration: none;
      color: #f5861d;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      border-bottom: none !important;
      text-align: left !important;
      font-size: 14px;
      font-family: "Quicksand-Regular";
      white-space: nowrap;
      text-shadow: none;
    }
  }
}
